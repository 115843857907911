import {
  Box,
  Button,
  Flex,
  HStack,
  Select,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from 'react-image-crop';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebouceEffects';
import 'react-image-crop/dist/ReactCrop.css';

/* global File, document */
// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

export default function EditImage({ image: imgSrc, onChange, onClose }) {
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);

  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const scale = 1;
  const rotate = 0;
  const [aspect, setAspect] = useState(16 / 9);

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  async function onSaveCrop() {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist');
    }

    // This will size relative to the uploaded image size.
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    // Create a temporary canvas for the final image
    const finalCanvas = document.createElement('canvas');
    finalCanvas.width = completedCrop.width * scaleX;
    finalCanvas.height = completedCrop.height * scaleY;

    const ctx = finalCanvas.getContext('2d');
    if (!ctx) {
      throw new Error('No 2d context');
    }

    // Enable image smoothing for better quality
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      finalCanvas.width,
      finalCanvas.height,
    );
    // You might want { type: "image/jpeg", quality: <0 to 1> } to
    // reduce image size
    // Preserve original file type if possible
    const originalType = image.src.startsWith('data:image/')
      ? image.src.split(';')[0].split('/')[1]
      : 'jpeg';

    // Convert to blob with high quality
    const blob = await new Promise((resolve, reject) => {
      try {
        finalCanvas.toBlob(
          (blobResult) => {
            if (blobResult) {
              resolve(blobResult);
            } else {
              reject(new Error('Failed to create blob'));
            }
          },
          `image/${originalType}`,
          1.0,
        );
      } catch (err) {
        reject(err);
      }
    });

    if (!blob) {
      throw new Error('Failed to create image blob');
    }

    const file = new File([blob], 'parfetts-nucleus-post-image', {
      type: `image/${originalType}`,
    });

    console.log('🚀 ~ onSaveCrop ~ file:11111111111', file);
    onChange(file);
    onClose();
    return file;
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        );
      }
    },
    100,
    [completedCrop, scale, rotate],
  );

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined);
    } else {
      setAspect(16 / 9);

      if (imgRef.current) {
        const { width, height } = imgRef.current;
        const newCrop = centerAspectCrop(width, height, 16 / 9);
        setCrop(newCrop);
        // Updates the preview
        setCompletedCrop(convertToPixelCrop(newCrop, width, height));
      }
    }
  }

  const handleRatioChange = (ratio) => {
    if (ratio) {
      setAspect(ratio);
      if (imgRef.current) {
        const { width, height } = imgRef.current;
        const newCrop = centerAspectCrop(width, height, ratio);
        setCrop(newCrop);
        // Updates the preview
        setCompletedCrop(convertToPixelCrop(newCrop, width, height));
      }
    }
  };

  return (
    <VStack alignItems={'flex-start'} gap={10}>
      <Box>
        <VStack gap={5} alignItems="flex-start" py={4}>
          {imgSrc && (
            <HStack>
              <Text>Aspect Ratio</Text>
              <Switch
                size="md"
                isChecked={aspect !== undefined}
                onChange={() => {
                  handleToggleAspectClick();
                }}
              />
              {aspect && (
                <Select
                  key={`aspect-${aspect}`}
                  ml={2}
                  width={'120px'}
                  placeholder="Ratio"
                  defaultValue={aspect}
                  onChange={(e) => handleRatioChange(e.target.value)}
                >
                  <option value={16 / 9}>16:9</option>
                  <option value={4 / 3}>4:3</option>
                  <option value={4 / 5}>4:5</option>
                  <option value={1 / 1}>1:1</option>
                  <option value={5 / 4}>5:4</option>
                  <option value={9 / 16}>9:16</option>
                </Select>
              )}
            </HStack>
          )}
        </VStack>
        <Flex
          direction={'row'}
          alignItems={'center'}
          border={'2px solid #cbd5e0'}
        >
          <Box width={'full'} height={'max'}>
            {!!imgSrc && (
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={aspect}
                minHeight={100}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            )}
          </Box>
          <Flex
            width={'full'}
            height={'max'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            {!!completedCrop && (
              <>
                <div>
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      border: '1px solid black',
                      objectFit: 'contain',
                      width: completedCrop.width,
                      height: completedCrop.height,
                    }}
                  />
                </div>
              </>
            )}
          </Flex>
        </Flex>
      </Box>

      {imgSrc && (
        <HStack>
          <Button onClick={onSaveCrop} colorScheme="blue" mr={2}>
            Save
          </Button>

          <Button colorScheme="blue" onClick={onClose}>
            Close
          </Button>
        </HStack>
      )}
    </VStack>
  );
}
