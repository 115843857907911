import React, { useEffect, useState, use } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  Box,
  Button,
  FormLabel,
  Grid,
  Input,
  Stack,
  Text,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { subYears, subDays } from 'date-fns';

import EditView from '../../components/EditView';
import { Field } from '../../components/Form';
import { FormSection, FormSectionInner } from '../../components/FormSection';
import Meta from '../../components/Meta';
import { CREATE, EDIT, FETCH, DELETE_MANY, CREATE_SCHEDULE } from './graphql';
import { setup } from './setup';
import NotificationDropdown from '../../components/NotificationDropdown';

const Index = ({ title }) => {
  const params = useParams();
  const toast = useToast();

  const [fetchData, { data, loading, error, refetch }] = useLazyQuery(FETCH, {
    variables: {
      id: params?.id,
    },
  });

  const [heading, setHeading] = useState('');
  const [body, setBody] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const [
    editNotification,
    { data: editData, loading: editLoading, error: editError },
  ] = useMutation(EDIT);

  const type = params.type.charAt(0).toUpperCase() + params.type.slice(1);

  const isEdit = type === 'Edit';

  useEffect(() => {
    if (isEdit && data) {
      setHeading(data?.notifications_findById?.message?.title);
      setBody(data?.notifications_findById?.message?.body);
    }
  }, [data]);

  useEffect(() => {
    if (editData) {
      toast({
        title: 'Saved',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }
    if (editError) {
      toast({
        title: 'Error, try again',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  }, [editData, editError]);

  return (
    <Meta title={` ${type} ${title}`}>
      <EditView
        fetchData={fetchData}
        loading={loading}
        error={error}
        data={data}
        setup={setup}
        gqlFetch={FETCH}
        gqlEdit={type === 'Schedule' ? CREATE_SCHEDULE : EDIT}
        gqlCreate={CREATE}
        gqlDelete={DELETE_MANY}
        extraParam={'recipients'}
        hideDelete={true}
        ignoreValues={
          type === 'Edit'
            ? ['devices', 'fired', 'recipients']
            : ['devices', 'fired']
        }
        editFields={type === 'Schedule' ? ['date'] : undefined}
      >
        <Grid
          templateColumns={{ base: '1fr', lg: '1fr 300px' }}
          w="100%"
          maxWidth="1000px"
          gap="25px"
        >
          <Stack spacing="20px" w="100%">
            <FormSection
              title={
                type === 'Create'
                  ? 'Add'
                  : type === 'Schedule'
                    ? `Schedule - ${title}`
                    : `Update - ${title}`
              }
            >
              {type === 'Schedule' ? (
                <FormSectionInner>
                  <Field
                    component="date"
                    name="date"
                    disablePast
                    showTimeSelect
                    excludeDateIntervals={[
                      {
                        start: subYears(new Date(), 10),
                        end: subDays(new Date(), 1),
                      },
                    ]}
                    timeIntervals={5}
                    rules={{ required: true }}
                  />
                </FormSectionInner>
              ) : (
                <FormSectionInner>
                  {type === 'Create' ? (
                    <Box>
                      <NotificationDropdown
                        refetch={refetch}
                        targetID={data?.notifications_findById._id}
                      />
                      <Field
                        component="text"
                        name="message.title"
                        customUpdate={editNotification}
                        placeholder="Heading"
                        defaultValue={searchParams.get('title')}
                        rules={{ required: true }}
                      // targetProperty="message.title"
                      // targetID={data?.notifications_findById._id}
                      // overrideTargetKey={'id'}
                      />

                      <Field
                        component="textarea"
                        name="message.body"
                        defaultValue={searchParams.get('body')}
                        placeholder="Body"
                        rules={{ required: true }}
                        help="This will be previewed under the main header"
                      // targetFullObject={data?.notifications_findById.message}
                      // targetProperty="message.body"
                      // targetID={data?.notifications_findById._id}
                      // overrideTargetKey={'id'}
                      />
                    </Box>
                  ) : (
                    <VStack gap={0} alignItems={'flex-start'}>
                      <FormLabel bg="transparent">Heading</FormLabel>
                      <Input
                        name="message.title"
                        value={heading}
                        onChange={(e) => setHeading(e.target.value)}
                        required={false}
                        size="md"
                        placeholder="Heading"
                        disabled={editLoading}
                      />

                      <FormLabel bg="transparent">Body</FormLabel>
                      <Textarea
                        name="message.body"
                        value={body}
                        onChange={(e) => setBody(e.target.value)}
                        placeholder="Body"
                        disabled={editLoading}
                        required={true}
                      />
                      <Text fontSize="sm" color="gray.500" textAlign="left">
                        This will be previewed under the main header
                      </Text>
                    </VStack>
                  )}
                </FormSectionInner>
              )}
              {isEdit && (
                <Button
                  isDisabled={!heading || !body}
                  bgColor={!heading || !body ? 'gray.400' : 'primary'}
                  onClick={async () => {
                    await editNotification({
                      variables: {
                        id: data?.notifications_findById._id,
                        record: {
                          message: {
                            title: heading,
                            body,
                          },
                        },
                      },
                    });
                    refetch();
                  }}
                  isLoading={editLoading}
                  size="sm"
                  w={'40%'}
                  m={'4'}
                  alignSelf={'center'}
                >
                  Update
                </Button>
              )}
            </FormSection>
          </Stack>
        </Grid>
      </EditView>
    </Meta>
  );
};

export default Index;
