import React, { useState, useCallback, useEffect } from 'react';
import {
  Flex,
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  Button,
} from '@chakra-ui/react';
import { GrSearch } from 'react-icons/gr';
import debounce from 'lodash.debounce';

const SearchInput = ({ changeSearch }) => {
  const [search, setSearch] = useState('');

  const confirm = () => {
    changeSearch(search);
  };

  const clearSearch = () => {
    setSearch('');
    changeSearch('');
  };

  const _searchFunction = useCallback(debounce(confirm, 500), [search]);

  useEffect(() => {
    console.log('search', search);
    // if (search) {
    _searchFunction();
    // }

    // Cancel the debounce on useEffect cleanup.
    return _searchFunction.cancel;
  }, [search, _searchFunction]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        _searchFunction();
      }}
    >
      <Flex align="center" pos="relative" overflow="visible">
        <InputGroup size="md">
          <InputLeftElement pointerEvents="none" children={<GrSearch />} />
          <Input
            placeholder="Search..."
            onChange={(e) => setSearch(e.target.value)}
            value={search || ''}
          />
          <InputRightElement width="4.5rem">
            <Button
              h="1.75rem"
              size="sm"
              variant="ghost"
              onClick={() => clearSearch()}
            >
              Clear
            </Button>
          </InputRightElement>
        </InputGroup>
      </Flex>
    </form>
  );
};

export default SearchInput;
