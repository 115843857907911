import React, { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Box,
  Flex,
  Image,
  Text,
  useColorModeValue,
  Select,
} from '@chakra-ui/react';

import { DeviceFrameset } from 'react-device-frameset';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import { FaChevronRight } from 'react-icons/fa';
import { AiFillPushpin } from 'react-icons/ai';
import Meta from '../../components/Meta';
import { FETCH_ALL } from './graphql';
import { FETCH_ALL as BRANCH_FETCH_ALL } from '../Branches/graphql';

import { setup } from './setup';
import 'react-device-frameset/styles/marvel-devices.min.css';
import ImageCarousel from '../../components/ImageCarousel/ImageCarousel';

dayjs.extend(relativeTime);

const StickyItem = ({ data }) => (
  <Flex
    direction={'row'}
    bg={'red'}
    p="0.5rem 1rem"
    justifyContent={'space-between'}
    alignItems={'center'}
  >
    {' '}
    <Flex alignItems={'center'}>
      <Image src="speech.png" w="18px" h="18px" />
      <Text as="strong" my="5px" color={'white'} ml="10px">
        {data?.title}
      </Text>
    </Flex>
    <FaChevronRight color="white" />
  </Flex>
);

const FeedItem = ({ data, sticky }) => {
  const [type, setType] = useState(null);

  useEffect(() => {
    switch (data?.type) {
      case 'posts':
        setType('Post');
        break;
      case 'surveys':
        setType('Survey');
        break;
      case 'jobs':
        setType('Job');
        break;
      default:
        break;
    }
  }, [data.type]);

  return (
    <Flex
      direction={'column'}
      bg={type === 'Survey' ? '#329ae3' : 'white'}
      py="1rem"
    >
      {data?.isSticky && (
        <Box alignSelf={'flex-end'} px={3}>
          <AiFillPushpin style={{ marginRight: '3px', color: 'red' }} />
        </Box>
      )}

      <Flex w="100%" justifyContent={'space-between'} px="1rem">
        <Text
          as={'small'}
          bg={type === 'Job' ? 'red' : '#cfbebe'}
          color={type === 'Job' ? 'white' : 'black'}
          borderRadius={'15px'}
          px="5px"
          align={'center'}
          fontFamily={'Quicksand'}
        >
          {type}
        </Text>
        <Text
          as={'small'}
          px="5px"
          align={'center'}
          color={type === 'Survey' ? 'white' : 'black'}
          fontFamily={'Quicksand'}
        >
          {dayjs(data?.publishedAt).fromNow()}
        </Text>
      </Flex>

      <Flex
        direction={'row'}
        justifyContent={'start'}
        alignItems={'center'}
        px="1rem"
        fontFamily={'Quicksand'}
      >
        <Text
          as="strong"
          my="5px"
          color={type === 'Survey' ? 'white' : 'black'}
        >
          {data?.title}
        </Text>
      </Flex>
      <ImageCarousel staticpage={data?.staticpage} />
      {/* <Image src={data?.staticpage?.hero?.imageUrl} /> */}
      <Box p="1rem" color={'#333'}>
        <div dangerouslySetInnerHTML={{ __html: data?.staticpage?.seo }}></div>
      </Box>
    </Flex>
  );
};

const List = ({ post }) => {
  const [fetchData, { loading, error, data }] = useLazyQuery(FETCH_ALL);
  const { data: branchData } = useQuery(BRANCH_FETCH_ALL);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const [stickies, setStickies] = useState([]);

  useEffect(() => {
    if (selectedBranch) {
      fetchData({
        variables: {
          filter: {
            active: true,
            visibility: {
              branches: [
                {
                  _id: selectedBranch,
                },
              ],
            },
          },
        },
      });
    } else {
      fetchData({
        variables: {
          filter: {
            active: true,
            visibility: {
              public: true,
            },
          },
        },
      });
    }
  }, [selectedBranch]);

  useEffect(() => {
    if (data?.feeds_pagination?.items?.length > 0) {
      setStickies(data?.feeds_pagination?.items?.filter((el) => el.isSticky));
    }
  }, [data]);

  const sortedData = [...(data?.feeds_pagination?.items || [])].sort((a, b) => {
    if (a.isSticky === b.isSticky) {
      // If isSticky values are the same, sort by publishedAt date
      return new Date(b?.publishedAt) - new Date(a?.publishedAt);
    }
    return a.isSticky ? -1 : 1;
  });

  return (
    <Meta title={setup.title}>
      <Flex
        direction={'column'}
        alignItems={'center'}
        mt="25px"
        bg={useColorModeValue('white', 'gray.700')}
        shadow="base"
        rounded={{
          base: 'md',
          sm: 'xl',
        }}
        w="100%"
        position={'relative'}
        p={'1rem'}
      >
        <Box py="2rem">
          <Text>Filter by:</Text>
          <Select
            placeholder="Filter posts that are unique to a branch"
            onChange={(e) => {
              console.log(e.target.value);
              setSelectedBranch(e.target.value);
            }}
          >
            {branchData?.branches_pagination?.items?.map((branch) => (
              <option value={branch?._id} key={branch._id}>
                {branch?.code}
              </option>
            ))}
          </Select>
        </Box>

        <DeviceFrameset
          device="iPhone X"
          color="black"
          style={{ overFlowX: 'scroll' }}
        >
          <Flex
            direction={'column'}
            px="5px"
            py="50px"
            bg="#f0f0f0"
            overflowY={'scroll'}
            maxHeight={'840px'}
          >
            {/* {stickies.map(sticky => (
              <Box key={sticky._id} mb="1rem">
                <StickyItem data={sticky} />
              </Box>
            ))} */}
            {sortedData?.map((el) => (
              <Box key={el._id} mb="1rem">
                <FeedItem data={el} />
              </Box>
            ))}
          </Flex>
        </DeviceFrameset>
      </Flex>
    </Meta>
  );
};

export default List;
