import { useEffect, useState } from 'react';
import {
  Input,
  Button,
  Stack,
  Box,
  AspectRatio,
  Flex,
  Image,
  Heading,
  Text,
  Spinner,
  HStack,
} from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { motion } from 'framer-motion';
import { UPLOAD_DATA } from '../Fields/File/graphql';
import { Editor } from './ImageEditor/Editor';

/* global FormData, fetch, URL */

const first = {
  rest: {
    rotate: '-15deg',
    scale: 0.95,
    x: '-50%',
    filter: 'grayscale(80%)',
    transition: {
      duration: 0.5,
      type: 'tween',
      ease: 'easeIn',
    },
  },
  hover: {
    x: '-70%',
    scale: 1.1,
    rotate: '-20deg',
    filter: 'grayscale(0%)',
    transition: {
      duration: 0.4,
      type: 'tween',
      ease: 'easeOut',
    },
  },
};

const second = {
  rest: {
    rotate: '15deg',
    scale: 0.95,
    x: '50%',
    filter: 'grayscale(80%)',
    transition: {
      duration: 0.5,
      type: 'tween',
      ease: 'easeIn',
    },
  },
  hover: {
    x: '70%',
    scale: 1.1,
    rotate: '20deg',
    filter: 'grayscale(0%)',
    transition: {
      duration: 0.4,
      type: 'tween',
      ease: 'easeOut',
    },
  },
};

const third = {
  rest: {
    scale: 1.1,
    filter: 'grayscale(80%)',
    transition: {
      duration: 0.5,
      type: 'tween',
      ease: 'easeIn',
    },
  },
  hover: {
    scale: 1.3,
    filter: 'grayscale(0%)',
    transition: {
      duration: 0.4,
      type: 'tween',
      ease: 'easeOut',
    },
  },
};

const PreviewImage = (props, ref) => (
  <Box
    bg="white"
    top="0"
    height="100%"
    width="100%"
    position="absolute"
    borderWidth="1px"
    borderStyle="solid"
    rounded="sm"
    borderColor="gray.400"
    as={motion.div}
    backgroundSize="cover"
    backgroundRepeat="no-repeat"
    backgroundPosition="center"
    backgroundImage={`url("https://image.shutterstock.com/image-photo/paella-traditional-classic-spanish-seafood-600w-1662253543.jpg")`}
    {...props}
    ref={ref}
  />
);

const MultipleImageUpload = ({ onUpload, targetID, multiple, targetKey }) => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isVideo, setIsVideo] = useState(false);

  const [files, setFiles] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  // Store cleanup functions for object URLs
  const [cleanupFns, setCleanupFns] = useState([]);

  const hasAvatarInUrl = window.location.href.includes('avatars');

  const { data } = useQuery(UPLOAD_DATA, {
    variables: {
      bulk: true,
      storagePath: '/staffAppStatic',
    },
  });
  const existingImages = targetID?.content?.images;

  const handleUploadFromEditor = async (file) => {
    const fileArray = isVideo ? file : [file];
    if (fileArray.length === 0) {
      setLoading(false);
      setIsOpen(false);
      return;
    }

    const formData = new FormData();

    for (let i = 0; i < fileArray.length; i++) {
      formData.append('file', fileArray[i]);
    }

    try {
      const response = await fetch(data?.bunnyFile_GetUploadData?.uploadUrl, {
        method: 'POST',
        'Content-Type': 'multipart/form-data; boundary=something',
        redirect: 'follow',
        headers: data?.bunnyFile_GetUploadData?.headers,
        body: formData,
      });
      const responseData = await response.json();

      if (response.ok) {
        setImages(responseData?.processed);
        setIsOpen(false);
        setLoading(false);
      } else {
        console.error('Failed to upload images');
        setIsOpen(false);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error uploading images:', error);
      setIsOpen(false);
      setLoading(false);
    }
  };

  const handleUpload = async () => {
    if (images.length > 0) {
      const formateImages = images.map((image) => {
        if (image?.imageUrl?.includes('mp4')) {
          return {
            ...image,
            autoPlay: true,
          };
        }
        return {
          ...image,
        };
      });

      const contentImages = multiple
        ? existingImages?.length > 0
          ? { images: [...existingImages, ...formateImages] }
          : { images: formateImages }
        : formateImages[0];

      setLoading(true);
      onUpload({
        variables: {
          record: hasAvatarInUrl
            ? { image_url: contentImages?.imageUrl }
            : { content: contentImages },
          [targetKey]: targetID?._id,
        },
        onCompleted: () => {
          setLoading(false);
          setImages([]);
        },
        onError: (err) => {
          console.error('Upload error:', err);
          setLoading(false);
        },
      });
    }
  };

  const handleFileChange = async (e) => {
    const { files: uploadedFiles } = e.target;
    if (uploadedFiles && uploadedFiles.length > 0) {
      setLoading(true);
      if (isVideo) {
        setFiles(uploadedFiles);
        handleUploadFromEditor(uploadedFiles);
      } else {
        // Use object URL instead of FileReader for better quality
        const objectUrl = URL.createObjectURL(uploadedFiles[0]);
        setFiles(objectUrl);

        // Store cleanup function
        const cleanup = () => URL.revokeObjectURL(objectUrl);
        setCleanupFns((prev) => [...prev, cleanup]);

        setIsOpen(true);
      }
    }
  };

  // Cleanup object URLs when component unmounts
  useEffect(
    () => () => {
      cleanupFns.forEach((cleanup) => cleanup());
    },
    [cleanupFns],
  );

  useEffect(() => {
    if (hasAvatarInUrl) {
      setIsVideo(true);
    }
  }, [hasAvatarInUrl]);

  return (
    <>
      <Editor
        image={files}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onChange={handleUploadFromEditor}
      />

      {!hasAvatarInUrl && (
        <HStack alignItems={'center'} spacing={0} py={3}>
          <Box
            borderWidth="1px"
            borderStyle="solid"
            borderColor="gray.400"
            p="2"
            borderTopLeftRadius={8}
            borderBottomLeftRadius={8}
            borderRight={0}
            cursor={'pointer'}
            onClick={() => {
              setFiles('');
              setIsVideo(false);
            }}
            backgroundColor={!isVideo ? 'blue.900' : 'white'}
          >
            <Text color={isVideo ? 'black' : 'white'}>Upload Image</Text>
          </Box>
          <Box
            borderWidth="1px"
            borderStyle="solid"
            borderColor="gray.400"
            p="2"
            borderTopRightRadius={8}
            borderBottomRightRadius={8}
            cursor={'pointer'}
            onClick={() => {
              setFiles('');
              setIsVideo(true);
            }}
            backgroundColor={isVideo ? 'blue.900' : 'white'}
          >
            <Text color={isVideo ? 'white' : 'black'}>Upload Video</Text>
          </Box>
        </HStack>
      )}

      <Box>
        <AspectRatio width="100%" cursor={'pointer'}>
          <Box
            borderColor="gray.300"
            borderStyle="dashed"
            borderWidth="2px"
            rounded="md"
            shadow="sm"
            role="group"
            transition="all 150ms ease-in-out"
            _hover={{
              shadow: 'md',
            }}
            as={motion.div}
            initial="rest"
            animate="rest"
            whileHover="hover"
          >
            <Box
              position="relative"
              height="100%"
              width="100%"
              cursor={'pointer'}
            >
              <Box
                position="absolute"
                top="0"
                left="0"
                height="100%"
                width="100%"
                display="flex"
                flexDirection="column"
                cursor={'pointer'}
              >
                <Flex
                  height="100%"
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justify={images.length > 0 ? 'flex-start' : 'center'}
                  spacing="4"
                  direction={'column'}
                  cursor={'pointer'}
                >
                  <Flex
                    direction={'column'}
                    alignItems={'center'}
                    position={'absolute'}
                    top={16}
                  >
                    <Box height="16" width="12" position="relative">
                      <PreviewImage
                        variants={first}
                        backgroundImage="url('https://image.shutterstock.com/image-photo/paella-traditional-classic-spanish-seafood-600w-1662253543.jpg')"
                      />
                      <PreviewImage
                        variants={second}
                        backgroundImage="url('https://images.unsplash.com/photo-1565299585323-38d6b0865b47?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2628&q=80')"
                      />
                      <PreviewImage
                        variants={third}
                        backgroundImage={`url("https://images.unsplash.com/photo-1563612116625-3012372fccce?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2480&q=80")`}
                      />
                    </Box>
                    <Stack p="8" textAlign="center" spacing="1">
                      <Heading fontSize="lg" color="gray.700" fontWeight="bold">
                        Drop{' '}
                        {hasAvatarInUrl
                          ? 'Avatar'
                          : isVideo
                            ? 'video'
                            : 'image'}{' '}
                        here
                      </Heading>
                      <Text fontWeight="light">or click to upload</Text>
                    </Stack>
                    {loading && <Spinner color="blue" />}
                  </Flex>
                </Flex>
              </Box>

              <Input
                accept={isVideo ? 'video/*' : 'image/*'}
                multiple={multiple}
                cursor={'pointer'}
                type="file"
                height="100%"
                width="100%"
                position="absolute"
                top="0"
                left="0"
                opacity="0"
                aria-hidden="true"
                onChange={handleFileChange}
              />
            </Box>
          </Box>
        </AspectRatio>

        <Box>
          {images.length > 0 && (
            <Stack
              direction="row"
              spacing={4}
              p={3}
              flexWrap={'wrap'}
              zIndex={1}
            >
              {images.map((image, index) => {
                if (image?.imageUrl?.includes('mp4')) {
                  return (
                    <iframe
                      key={index}
                      title="post-video"
                      src={image?.imageUrl}
                      allowFullScreen
                    />
                  );
                }
                return (
                  <Image
                    key={index}
                    src={image?.imageUrl}
                    width={
                      images.length > 1
                        ? `${30 - (images.length + 5)}%`
                        : '100%'
                    }
                    cursor={'pointer'}
                    alt={`Uploaded Image ${index}`}
                  />
                );
              })}
            </Stack>
          )}
        </Box>

        {images?.length > 0 && (
          <Flex
            direction="column"
            justify="center"
            mt={4}
            alignItems={'center'}
          >
            <Button onClick={() => handleUpload()} alignSelf={'center'}>
              Upload
            </Button>
          </Flex>
        )}
      </Box>
    </>
  );
};

export default MultipleImageUpload;
